<template>
  <div class="thing_goods_list">
    <el-card>
      <el-row class="left m-b-10">
        <el-col :span="24">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="addCategory">添加</el-button>
          <el-button type="default" size="mini" @click="openTable">展开/折叠</el-button>
          <el-button type="default" size="mini" icon="el-icon-refresh-right" @click="getList">刷新</el-button>
        </el-col>
      </el-row>

      <el-table
          :data="list"
          ref="thingCategoryRef"
          style="width: 100%; margin-bottom: 20px;"
          row-key="category_id"
          border
          :default-expand-all="isOpen"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
        <el-table-column label="分类名称" width="150">
          <template slot-scope="scope">
            {{scope.row.category_name}}
          </template>
        </el-table-column>
        <el-table-column label="是否显示" prop="is_show">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.is_show">是</span>
              <span v-else>否</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort_order"></el-table-column>
        <el-table-column label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" type="primary" plain @click="edit(scope.row.category_id)">编辑</el-button>
              <el-button size="mini" type="danger" plain @click="remove(scope.row.category_id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog :title="addTitle" width="600px" :visible.sync="addVisible">
      <el-form ref="categoryForm" :rules="categoryRules" :model="info" label-width="80px" style="padding-left:40px">
        <el-form-item label="分类封面" prop="category_thumb">
          <div class="previewUpload">
            <div v-show="info.category_thumb" class="previewItem">
              <img :src="info.category_thumb" alt="">
              <i class="el-icon-close delIcon" @click="delThumb(index)"></i>
            </div>
            <div class="previewItems" @click="openUploadDialog(1,'category_thumb')"><i class="el-icon-plus previewadd"></i></div>
          </div>
        </el-form-item>
<!--        <el-form-item label="上级分类" prop="parent_id">-->
<!--          <treeselect v-model="info.parent_id" :options="category_list" :normalizer="normalizer" :show-count="true" placeholder="请选择上级菜单" />-->
<!--        </el-form-item>-->
        <el-form-item label="分类名称" prop="category_name">
          <el-input v-model="info.category_name" maxlength="15" show-word-limit placeholder="请输入分类名称"></el-input>
        </el-form-item>
        <el-form-item label="是否显示" prop="is_show" style="text-align: left;">
          <el-radio v-model="info.is_show" label="1">是</el-radio>
          <el-radio v-model="info.is_show" label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="排序" prop="sort_order">
          <el-input v-model.number="info.sort_order" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="category_desc">
          <el-input type="textarea" :rows="4" v-model="info.category_desc" placeholder="请输入描述"></el-input>
        </el-form-item>
        <el-button type="default" @click="addVisible=false">取消</el-button>
        <el-button type="primary" @click="subAdd">确认</el-button>
      </el-form>
    </el-dialog>

    <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>

  </div>
</template>

<script>
  import Treeselect from "@riophae/vue-treeselect";
  import Upload from '@/components/Upload/Upload.vue'
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  export default {
    components: {Treeselect,Upload},
    data () {
      return {
        isOpen: true,
        list:[],

        addTitle: "新增分类",
        category_id: 0,
        addVisible: false,
        categoryRules:{
          category_name:[
            {required: true, message:'请输入分类名称', trigger: 'blur'}
          ]
        },
        info:{
          category_id: '',
          category_name:'',
          category_desc:'',
          parent_id:'0',
          sort_order:99,
          is_show: '1',
          category_thumb:'',
        },
        category_list: [],

        //上传图片
        uploadVisible:false,
        pictureType:'',
        chooseImgCount:0,


      }
    },
    created() {
      if(!this.$store.state.userInfo) {
        // 防止未登录
        this.$router.push({
          path: '/login'
        })
      } else {
        this.getList();
        this.getCategoryList();
      }
    },
    methods: {
      getList(){
        var url = 'thing/category_list';
        let params = {};
        this.fd_post(url, params).then((res) => {
          console.log(res);
          if(res.status) {
            this.list = res.list;
            console.log(this.list);
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      },
      //折叠
      openTable() {
        this.isOpen = !this.isOpen;
        this.fold(this.list, this.isOpen);
      },
      fold(arr, isOpen) {
        arr.forEach((i) => {
          this.$refs.thingCategoryRef.toggleRowExpansion(i, isOpen);
          if(i.children) {
            this.fold(i.children, isOpen);
          }
        });
      },
      //新增
      addCategory() {
        this.addTitle = "新增分类"
        this.info = {
          category_id: '',
          category_name:'',
          category_desc:'',
          parent_id:'0',
          sort_order:99,
          is_show: '1',
          category_thumb:'',
        }
        this.addVisible = true;
        // this.$router.push('/thing_category_add');
      },
      edit(category_id) {
        this.addTitle = "修改分类"
        this.category_id = category_id;
        // this.$router.push({path:'/thing_category_add',query: {id: category_id}})
        this.getCategoryDetail();
        this.addVisible = true;
      },
      remove(category_id) {
        this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var url = 'thing/deleteCategory';
          let params = {
            category_id: category_id
          };
          this.fd_post(url, params).then((res) => {
            if(res.status) {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          }).catch((err) => {
            this.$message.error('网络错误');
          });
        }).catch(() => {
          this.$message.info('已取消删除');
        });
      },


      getCategoryList(){
        var url = 'thing/category_list_select';
        let params = {};
        this.fd_post(url, params).then((res) => {
          console.log(res);
          if(res.status) {
            this.category_list = res.list;
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      },
      getCategoryDetail(){
        var url = 'thing/category_detail';
        let params = {
          category_id: this.category_id
        };
        this.fd_post(url, params).then((res) => {
          if(res.status) {
            this.info = res.detail;
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      },
      normalizer(node) {
        console.log(node);
        if(node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.category_id,
          label: node.category_name,
          children: node.children,
        };
      },
      //上传图片
      openUploadDialog(count,pictureType){
        this.pictureType = pictureType;
        this.chooseImgCount = count;
        this.uploadVisible = true;
      },
      closeDelDialog(){
        this.uploadVisible=false;
      },
      receiveRes(chooseList, pictureType){
        console.log(chooseList);
        if(pictureType==='category_thumb'){
          this.info.category_thumb = chooseList[0].img_path;
        }
        this.uploadVisible=false;
      },
      delThumb(index){
        this.info.category_thumb = '';
      },
      //提交
      subAdd(){
        this.$refs.categoryForm.validate(valid=>{
          console.log(this.info);
          if(!valid) return;
          else{
            this.fd_post('thing/category_doedit', this.info).then((res) => {
              if(res.status){
                this.$refs.categoryForm.resetFields();
                this.$message.success("操作成功");
                this.addVisible = false;
                this.getList();
              }else{
                this.$message.error(res.msg);
              }
            }).catch((err) => {
              this.$message.error('网络错误');
            });
          }
        })
      }


    },
  }
</script>

<style lang="scss" scoped>
  .thing_goods_list{
    .previewUpload{
      display: flex;
      flex-wrap: wrap;
      .previewItems{
        width: 178px;
        height: 178px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        margin:0 10px 10px 0;
        cursor: pointer;
        position: relative;
        .previewadd {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
        }
        &:hover{
          border-color: #409EFF;
        }
      }
      .previewItem{
        width: 178px;
        height: 178px;
        border-radius: 6px;
        margin:0 10px 10px 0;
        cursor: pointer;
        position: relative;
        .delIcon{
          display: none;
        }
        &:hover{
          .delIcon{
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            color: white;
            font-size: 30px;
          }
        }

        img{
          width: 100%;
          height: 100%;
        }

      }
    }
  }
</style>